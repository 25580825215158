function handleMarker({ map, marker, infoWindow, name, address }) {
	infoWindow.setContent(`<div class="map-item-info"><p class="font-weight-bold">${name}</p><p><strong>Address:</strong> ${address}</p></div>`);
	infoWindow.open(map, marker);
}

function handleResult($agent) {
	$('.agent.active').removeClass('active');
	$agent.addClass('active');
}

const anchor = document.querySelector('#findAnAgent');

if (anchor) {
	(function() {
		$('.find-agent-form').validate({
			onfocusout: function(element) {
                this.element(element);
            },
			rules: {
				zipCode: {
					required: true,
					exactlength: 5,
					digits: true,
				},
			},
			messages: {
				zipCode: {
					required: 'Zip/Postal Code is required.',
					exactlength: 'Zip Code should be exactly 5 digits long.',
					digits: 'Only digits allowed for Zip Code.',
				},
			},
			errorClass: 'error-msg',
			errorElement: 'span',
			highlight: function ( element, errorClass, validClass ) {
				$(element)
					.parents('p')
					.addClass('input-error');
			},
			unhighlight: function (element, errorClass, validClass) {
				$(element)
					.parents('p')
					.removeClass('input-error');
			},
		});

		const $map = $('#map');
		google.maps.visualRefresh = true;

		// center and zoom are defined later to fit all markers
		const mapOptions = {
			mapTypeId: google.maps.MapTypeId.G_NORMAL_MAP
		};

		const map = new google.maps.Map($map[0], mapOptions);
		const latlngbounds = new google.maps.LatLngBounds();
		const infoWindow = new google.maps.InfoWindow();

		const agentDivs = $('.agent');
		if (!agentDivs.length) {
			$map.hide();
		}

		$.each(agentDivs, function(i, agent) {
			const id = $(agent).data('num');
			const lat = parseFloat($(agent).data('latitude'));
			const long = parseFloat($(agent).data('longitude'));
			const name = $(agent).find('.jsName');
			const address = $(agent).find('.jsAddress').text();
			const icon = $(agent).find('.js-icon-wrapper');

			const latLng = new google.maps.LatLng(lat, long);

			const agentMarker = new google.maps.Marker({
				position: latLng,
				map: map,
				title: name.text(),
				label: {
					text: id.toString(),
					className: 'map-icon-num',
					color: '#fff',
				}
			});

			latlngbounds.extend(agentMarker.position);
			agentMarker.setIcon('/Assets/images/pni-pin.png');

			const markerOptions = {
				map,
				marker: agentMarker,
				infoWindow,
				name: name.text(),
				address,
			};

			google.maps.event.addListener(agentMarker, 'click', () => {
				handleMarker(markerOptions);
				handleResult($(agent));
			});
			google.maps.event.addListener(infoWindow,'closeclick', () => {
				$('.agent.active').removeClass('active');
			});
			icon.on('click', () => {
				map.panTo(latLng);
				handleMarker(markerOptions);
				handleResult($(agent));
			});
			name.on('click', () => {
				map.panTo(latLng);
				handleMarker(markerOptions);
				handleResult($(agent));
			});
		});
		//center and zoom
		map.setCenter(latlngbounds.getCenter());
		if (agentDivs.length > 1) {
			map.fitBounds(latlngbounds);
		} else {
			map.setZoom(12);
		}
    })();
}
