const navToggle = document.querySelector('.itm-nav-toggle');
const navWrapper = document.querySelector('.navigation-wrapper');
const navLink = document.querySelectorAll('.main-nav-link.has-mega-menu');
const navBackLink = document.querySelectorAll('.nav-back-link');
const body = document.body;

const topMenu = $('.nav-wrapper');
const topMenuLinks = $('.nav-wrapper > ul > li');

navToggle.addEventListener('click', function(e) {
	e.preventDefault();
	ToggleMobileNav();
});

navLink.forEach(function(item) {
	if (window.innerWidth <= 1024) {
		item.addEventListener('click', function(e) {
			e.preventDefault();
			e.target.nextElementSibling.classList.add('open-mega-menu');
		});
	}
});

navBackLink.forEach(function(item) {
	item.addEventListener('click', function(e) {
		e.preventDefault();
		e.target.parentElement.classList.remove('open-mega-menu');
	});
});

function hideMenus() {
	$.each(topMenuLinks, function() {
		$(this).find('.mega-menu').hide();
	});
}

topMenu.on('mouseleave', hideMenus);
topMenuLinks.on('mouseenter', function(e) {
	hideMenus();
	$(this).find('.mega-menu').show();
});

$(window).on('resize', () => {
	// prevent resize check due to keyboard being open on mobile
	if (!$(document.activeElement).is('.search-input')) {
		ResetNavigation();
	}
});

// prevent search bar animation during load
$(window).on('load', () => {
	setTimeout(() => {
		$('.header-search-wrapper').removeClass('preload');
	}, 500);
});

function ToggleMobileNav() {
	if (navToggle.querySelector('.fas').classList.contains('fa-bars')) {
		body.classList.add('locked');
		navToggle.querySelector('.fas').classList.remove('fa-bars');
		navToggle.querySelector('.fas').classList.add('fa-times');
		navWrapper.classList.add('open-nav');
	} else {
		body.classList.remove('locked');
		navToggle.querySelector('.fas').classList.remove('fa-times');
		navToggle.querySelector('.fas').classList.add('fa-bars');
		navWrapper.classList.remove('open-nav');
		navWrapper.querySelector('.mega-menu').classList.remove('open-mega-menu');
	}
}

function ResetNavigation() {
	body.classList.remove('locked');
	body.classList.remove('fixed-header');
	navToggle.querySelector('.fas').classList.remove('fa-times');
	navToggle.querySelector('.fas').classList.add('fa-bars');
	navWrapper.classList.remove('open-nav');
	if (navWrapper.querySelector('.mega-menu')) {
		navWrapper.querySelector('.mega-menu').classList.remove('open-mega-menu');
	}
	hideMenus();
}

if (document.querySelector('header') && window.innerWidth < 768) {
    //scroll add fixed class
    const stickyNavTop = $('header').offset().top;

    var stickyNav = function () {
        const scrollTop = $(window).scrollTop(); // our current vertical position from the top

        if (scrollTop > stickyNavTop) {
            $('body').addClass('fixed-header');
        } else {
            $('body').removeClass('fixed-header');
        }
    };
	
    stickyNav();

    $(window).scroll(function () {
        stickyNav();
    });
}