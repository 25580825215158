(function($) {
    $.validator.addMethod("exactlength", function(value, element, params) {
        return this.optional(element) || !([].concat(params).indexOf(value.length) === -1);
    }, $.validator.format("Value should be exactly {0} characters long."));
    
    $.validator.addMethod("noSpecialCharacters",function(value, element){
        return this.optional(element) || /^[0-9a-zA-Z]+$/.test(value);
    }, "No special characters allowed.");

    $.validator.addMethod("phoneNumberFormat",function(value,element){
        return this.optional(element) || /^(?:\(\d{3}\) |\d{3}-)\d{3}-\d{4}$/.test(value);
    },"This phone number is invalid. Make sure it's written xxx-xxx-xxxx.");

    $.validator.addMethod('fileSize', function(value, element, param) {
        // param = size (en bytes) 
        // element = element to validate (<input>)
        // value = value of the element (file name)
        const maxSize = typeof param === 'number' ? param : element.dataset.maxSize || 1024*1024*1024;
        return this.optional(element) || (element.files[0].size <= maxSize);
    }, $.validator.format("The file is too big. Max size is {0} bytes."));

    $.validator.addMethod( "extension", function( value, element, param ) {
        let targetValue = param;
        if (typeof param === 'function') {
            targetValue = param(element);
        } else if (typeof param !== 'string') {
            targetValue = element.getAttribute('accept') || '.*';
        }
        return this.optional( element ) || value.match( new RegExp( "(" + targetValue.replace( /,/g, "|" ) + ")$", "i" ));
    }, "The file type is not valid.");
    
    $.validator.methods.email = function( value, element ) {
        // Changing the default email regexp to match backend validation rules
        return this.optional(element) || /^([0-9a-zA-Z]([-.+\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/.test(value);
    }

    $.validator.addMethod("bankRoutingNumber", function(value, element, params) {
        const isOptional = this.optional(element);
        if (isOptional) {
            return isOptional;
        }

        const digitsOnly = value.replace(/\D/g, '');
        if (digitsOnly.length !== 9) {
            return false;
        }

        let sum = 0;
        for (i = 0; i < digitsOnly.length; i += 3) {
            sum += parseInt(digitsOnly.charAt(i), 10) * 3;
            sum += parseInt(digitsOnly.charAt(i + 1), 10) * 7
            sum += parseInt(digitsOnly.charAt(i + 2), 10);
        }
        return sum !== 0 && sum % 10 === 0;
    }, "The routing number is invalid.");

    $.validator.addMethod("bankAccountNumber", function(value, element, params) {
        return this.optional(element) || /^\d{5,17}$/.test(value);
    }, "The bank account number is invalid.");
})(jQuery);