const anchor = document.querySelector('#reportAnIdCard');

if (anchor) {
	(function() {
		$('.request-id-form').validate({
			onfocusout: function(element) {
				this.element(element);
			},
			rules: {
				PolicyHolderNumber: {
					required: true,
					exactlength: 10,
					noSpecialCharacters: true,
				},
				PolicyHolderLastName: 'required',
				PolicyHolderZipcode: {
					required: true,
					exactlength: 5,
					digits: true,
				},
				IsTermsAndConditionsAccepted: 'required',
			},
			messages: {
				policyNumber: {
					required: 'Policy Number is required.',
					exactlength: 'Policy Number should be exactly 10 characters long.',
				},
				PolicyHolderLastName: {
					required: 'Last Name is required.',
				},
				PolicyHolderZipcode: {
					required: 'Zip/Postal Code is required.',
					exactlength: 'Zip Code should be exactly 5 digits long.',
					digits: 'Only digits allowed for Zip Code.',
				},
			},
			errorClass: 'error-msg',
			errorElement: 'span',
			errorPlacement: function(error, element) {
				if (element.is('input[name="IsTermsAndConditionsAccepted"]')) {
					error.insertAfter('.custom-checkbox-label');
				} else {
					error.insertAfter(element);
				}
			},
			highlight: function ( element, errorClass, validClass ) {
				$(element)
					.parents('p')
					.addClass('input-error');
			},
			unhighlight: function (element, errorClass, validClass) {
				$(element)
					.parents('p')
					.removeClass('input-error');
			}
		});
	})();
}