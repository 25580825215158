function initCarousel() {
    $('.hero-carousel').slick({
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    arrows: false,
                }
            }
        ]
    });
}

initCarousel();
