function handleMarker({ map, marker, infoWindow, name, address }) {
    infoWindow.setContent(`<div class="map-item-info"><p class="font-weight-bold">${name}</p><p><strong>Address:</strong> ${address}</p></div></div>`);
    infoWindow.open(map, marker);
}

function handleResult($agent) {
    $('.agent.active').removeClass('active');
    $agent && $agent.addClass('active');
}

function getData() {
    var data = [];
    $('.agent').each((i, item) => {
        data.push({
            'Name': $(item).find('.js-agent-name'),
            'Phone': $('#agent-phone-' + (i + 1)).text(),
            'GeoLong': parseFloat($(item).data('long')),
            'GeoLat': parseFloat($(item).data('lat')),
            'Icon': $(item).find('.js-icon-wrapper'),
            'Id': $(item).data('num'),
            'Address': $(item).data('address')
        });
    });
    return data;
}

const anchor = document.querySelector('#findAShop');

if (anchor) {
    (function() {
        $('.find-shop-form').validate({
			onfocusout: function(element) {
                this.element(element);
            },
			rules: {
				zipCode: {
					required: true,
					exactlength: 5,
					digits: true,
				},
			},
			messages: {
				zipCode: {
					required: 'Zip/Postal Code is required.',
					exactlength: 'Zip Code should be exactly 5 digits long.',
					digits: 'Only digits allowed for Zip Code.',
				},
			},
			errorClass: 'error-msg',
			errorElement: 'span',
			highlight: function ( element, errorClass, validClass ) {
				$(element)
					.parents('p')
					.addClass('input-error');
			},
			unhighlight: function (element, errorClass, validClass) {
				$(element)
					.parents('p')
					.removeClass('input-error');
			},
		});

        const $map = $('#map');
        google.maps.visualRefresh = true;

        const mapOptions = {
            mapTypeId: google.maps.MapTypeId.G_NORMAL_MAP
        };

        const map = new google.maps.Map($map[0], mapOptions);
        const latlngbounds = new google.maps.LatLngBounds();
        const infoWindow = new google.maps.InfoWindow();
    
        var data = getData();
        if (!data.length) {
            $map.hide();
        }

        $.each(data, (i, item) => {
            const latLng = new google.maps.LatLng(item.GeoLat, item.GeoLong);
    
            const agentMarker = new google.maps.Marker({
                position: latLng,
                map: map,
                title: item.Name.text(),
                label: {
                    text: item.Id.toString(),
                    className: 'map-icon-num',
                    color: '#fff',
                }
            });
    
            latlngbounds.extend(agentMarker.position);
            agentMarker.setIcon('/Assets/images/pni-pin.png');

            const markerOptions = {
                map,
                marker: agentMarker,
                infoWindow,
                name: item.Name.text(),
                address: item.Address
            }

            google.maps.event.addListener(agentMarker, 'click', () => {
                handleMarker(markerOptions);
                handleResult($(`.agent[data-num=${item.Id}]`));
            });
            google.maps.event.addListener(infoWindow, 'closeclick', () => {
                handleResult(null);
            });
            item.Icon.on('click', () => {
                map.panTo(latLng);
                handleMarker(markerOptions);
                handleResult($(`.agent[data-num=${item.Id}]`));
            });
            item.Name.on('click', () => {
				map.panTo(latLng);
				handleMarker(markerOptions);
				handleResult($(`.agent[data-num=${item.Id}]`));
			});
        });
        map.setCenter(latlngbounds.getCenter());
        if (data.length > 1) {
			map.fitBounds(latlngbounds);
		} else {
			map.setZoom(12);
		}
    })();
}