function setDotsOffset($dots, dotsOffset = 0) {
	$dots.css('transform', `translateX(${dotsOffset}px)`);
}

function initDots($dots, maxDots, dotsOffset) {
	if ($dots && $dots.find('li').length > maxDots) {
		$dots.wrap("<div class='slick-dots-container'></div>");
		$dots.find('li').each(function (i) {
			$(this).addClass('dot-i-' + i);
		});
		setDotsOffset($dots, dotsOffset);
	}
}

function handleTestimonial() {
	const maxDots = 8;
	const offsetInterval = 32;

	$('.carousel-slide .st-block-quote').each(function() {
		$(this).children().first().prepend('<span>“</span>');
		$(this).children().last().append('<span>”</span>');
	});

	$('.testimonial-carousel').each(function() {
		const $slider = $(this);
		let dotsOffset = 0;

		$slider.on('init', function(event, slick) {
			if (slick.$dots) {
				initDots(slick.$dots, maxDots, 0);
			}
		});

		$slider.on('breakpoint', function(event, slick) {
			if (!slick.$dots) return;
			
			// handle dots offset
			dotsOffset = 0;
			const $dots = slick.$dots;
			const dotsNumber = $dots.find('li').length;
			const currentDot = $dots.find('.slick-active').index();
			if (currentDot + 2 > maxDots) {
				dotsOffset = (maxDots - (currentDot + 2)) * 32;
				if (currentDot == dotsNumber - 1) {
					dotsOffset += 32;
				}
			}

			setDotsOffset($dots);
			initDots($dots, maxDots, dotsOffset);
		});
 
		$slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			const $dots = slick.$dots;
			if (!$dots) return;

			const dotsNumber = $dots.find('li').length;
			// multiplier is applied, remove to look for dots
			currentSlide /= slick.options.slidesToShow;
			nextSlide /= slick.options.slidesToShow;
			if (dotsNumber > maxDots) {
				// cycle the slider
				if (nextSlide === 0) {
					dotsOffset = 0;
					setDotsOffset($dots, dotsOffset);
				} else if (nextSlide === (dotsNumber - 1)) {
					dotsOffset = -(offsetInterval * (dotsNumber - maxDots));
					setDotsOffset($dots, dotsOffset);
				}

				// general scrolling
				if (nextSlide > currentSlide) {
					if (nextSlide === Math.abs(dotsOffset / offsetInterval) + (maxDots - 1) && nextSlide < dotsNumber - 1) {
						dotsOffset -= offsetInterval;
						setDotsOffset($dots, dotsOffset);
					}
				} else {
					if (nextSlide === Math.abs(dotsOffset / offsetInterval) && nextSlide > 0) {
						dotsOffset += offsetInterval;
						setDotsOffset($dots, dotsOffset);
					}
				}
			}
		});

		$slider.slick({
			dots: false,
			slidesToScroll: 1,
			slidesToShow: 3,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						variableWidth: false
					}
				},
				{
					breakpoint: 768,
					settings: {
						dots: true,
						arrows: false,
						slidesToScroll: 2,
						slidesToShow: 2,
						variableWidth: false
					}
				},
				{
					breakpoint: 530,
					settings: {
						dots: true,
						arrows: false,
						slidesToScroll: 1,
						slidesToShow: 1,
						variableWidth: false
					}
				}
			]
		});
	});
}

handleTestimonial();
