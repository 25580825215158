(function() {
	const anchor = document.querySelector('#reportAClaim');
	if (!anchor) {
		return;
	}
	const isPmicSite = $("body").hasClass('theme-pmic');
	const $form = $('.report-a-claim-form');

    initializeFileUploadControls();
	const formValidator = configureFormValidation();
	
	$('input.jsAccountType').on('change', displayFieldsBasedOnAccountType);
	displayFieldsBasedOnAccountType();
	
	$('.jsEnableEmailNotifications, .jsEnablePhoneNotifications').on('change', enableNotificationFieldsBasedOnCheckbox);


	function initializeFileUploadControls() {
		var uploadFileBtn = document.querySelectorAll('.upload-file-btn');

		uploadFileBtn.forEach(function(item) {
			item.addEventListener('click', function(e) {
				var uploadBtn = e.target.nextElementSibling;
				var deleteBtn = e.target.parentElement.lastElementChild;

				uploadBtn.addEventListener('change', function(event) {
					var fullPath = event.target.value;
					var fileName = fullPath.split(/(\\|\/)/g).pop();
					var parent = event.target.parentElement;

					deleteBtn.style.display = 'block';
					parent.nextElementSibling.setHTML(fileName);

					$form.validate().element(uploadBtn);
				});

				deleteBtn.addEventListener('click', function(event) {
					var parent = event.target.parentElement;

					event.target.previousElementSibling.value === '';
					parent.nextElementSibling.innerHTML = 'No File Chosen';
					event.target.style.display = 'none';

					uploadBtn.value = '';
					$form.validate().element(uploadBtn);
				});
			});
		});
	}

	function displayFieldsBasedOnAccountType() {
		const accountType = $('.jsAccountType:checked').val();
		if (accountType === 'Personal') {
			$('.jsPersonalSpecific').show();
			$('.jsCommercialSpecific').hide();
		}
		else {
			$('.jsPersonalSpecific').hide();
			$('.jsCommercialSpecific').show();
		}
	}

	function enableNotificationFieldsBasedOnCheckbox(e) {
		const $checkbox = $(e.currentTarget);
		const $textbox = $checkbox.parent().next('.input-wrapper').find('input');
		if ($checkbox.prop('checked')) {
			$textbox.prop('disabled', false);
		} else {
			$textbox.prop('disabled', true).val('');
			formValidator.element($textbox);
		}
	}

	function configureFormValidation() {
		let skipPolicyHolderValidation = false;

		$form.on('click', 'button', function(e) {
			if ($(e.currentTarget).is('.jsSkipPolicyHolderValidation')) {
				skipPolicyHolderValidation = true;
				$form.find('input:visible').val('');
			} else {
				skipPolicyHolderValidation = false;
			}
		});

		const fileInputValidator = {
			fileSize: true,
			extension: true
		};
		const fileInputMessages = {
			fileSize: 'The file is too big. Max file size is 10 MB',
			extension: (_, element) => 'The file type is not valid. The supported extensions are ' + element.getAttribute('accept').replace(/,/g, ', ')
		};

		return $form.validate({
			onfocusout: function(element) {
				this.element(element);
			},
			rules: {
				AccountType: 'required',
				CustomerType: 'required',
				PolicyNumber: {
					required: true,
					exactlength: isPmicSite ? [7, 10] : 10,
					noSpecialCharacters: true,
				},
				LastName: {
					required: '.jsAccountType' // We only require LastName on the first screen where .jsAccountType radio is displayed.
				},
				CompanyName: 'required',
				ZipCode: {
					required: true,
					exactlength: 5,
					digits: true,
				},
				AcceptTerms: 'required',
				DateOfBirth: {
					required: () => !skipPolicyHolderValidation
				},
				AgencyId: {
					required: () => !skipPolicyHolderValidation
				},
				CellPhone: {
					required: true,
					phoneNumberFormat: true,
				},
				WorkPhone: {
					phoneNumberFormat: true,
				},
				HomePhone: {
					phoneNumberFormat: true,
				},
				Email: {
					required: true,
					email: true,
				},
				AccidentDate: 'required',
				AccidentLocationAddress: 'required',
				AccidentCity: 'required',
				AccidentState: 'required',
				AccidentZipCode: {
					required: true,
					exactlength: 5,
					digits: true,
				},
				AccidentReport: 'required',
				Vehicle: 'required',
				Driver: 'required',
				NotificationsEmail: {
					required: input => !$(input).attr('disabled'),
					email: true
				},
				NotificationsPhone: {
					required: input => !$(input).attr('disabled'),
					phoneNumberFormat: true
				}, 
				fileUpload1: fileInputValidator,
				fileUpload2: fileInputValidator,
				fileUpload3: fileInputValidator,
				fileUpload4: fileInputValidator,
				fileUpload5: fileInputValidator
			},
			messages: {
				AccountType: {
					required: 'Account Type is required.'
				},
				CustomerType: {
					required: 'Please pick one.'
				},
				PolicyNumber: {
					required: 'Policy Number is required.',
					exactlength: isPmicSite ? 'Policy Number should be exactly 7 or 10 characters long.' : 'Policy Number should be exactly 10 characters long.',
				},
				LastName: {
					required: 'Last Name is required.',
				},
				CompanyName: {
					required: 'Company Name is required.',
				},
				ZipCode: {
					required: 'Zip Code is required.',
					exactlength: 'Zip Code should be exactly 5 digits long.',
					digits: 'Only digits allowed for Zip Code.',
				},
				AcceptTerms: {
					required: 'Please accept terms and conditions.'
				},
				CellPhone: {
					required: 'Phone Number is required.',
				},
				Email: {
					required: 'Email is required.',
					email: `This email is invalid. Make sure it's written example@email.com.`,
				},
				AccidentDate: {
					required: 'Accident Date is required.',
				},
				AccidentLocationAddress: {
					required: 'Accident Location is required.',
				},
				AccidentCity: {
					required: 'City is required.',
				},
				AccidentState: {
					required: 'State is required.',
				},
				AccidentZipCode: {
					required: 'Zip/Postal Code is required.',
					exactlength: 'Zip Code should be exactly 5 digits long.',
					digits: 'Only digits allowed for Zip Code.',
				},
				AccidentReport: {
					required: 'This information is required.',
				},
				Vehicle: {
					required: 'Vehicle is required.',
				},
				Driver: {
					required: 'Driver is required.',
				},
				NotificationsEmail: {
					required: 'Email is required.',
					email: `This email is invalid. Make sure it's written example@email.com.`,
				},
				NotificationsPhone: {
					required: 'Mobile number is required.'
				},
				fileUpload1: fileInputMessages,
				fileUpload2: fileInputMessages,
				fileUpload3: fileInputMessages,
				fileUpload4: fileInputMessages,
				fileUpload5: fileInputMessages
			},
			errorClass: 'error-msg',
			errorElement: 'span',
			errorPlacement: function(error, element) {
				if (element.is('input.jsAcceptTerms')) {
					error.insertAfter('.custom-checkbox-label');
				} else if (element.is(':radio')) {
					error.insertBefore(element.closest('div'));
				} else if (element.is('.upload-file-input')) {
					error.insertBefore(element.closest('.upload-file-container'))
				} else {
					error.insertAfter(element);
				}
			},
			highlight: function ( element, errorClass, validClass ) {
				let parentTag = 'p';
				if ($(element).is('#NotificationsPhone') || $(element).is('#NotificationsEmail')) {
					parentTag = '.input-wrapper';
				}
				$(element)
					.parents(parentTag)
					.addClass('input-error');
			},
			unhighlight: function (element, errorClass, validClass) {
				let parentTag = 'p';
				if ($(element).is('#NotificationsPhone') || $(element).is('#NotificationsEmail')) {
					parentTag = '.input-wrapper';
				}
				$(element)
					.parents(parentTag)
					.removeClass('input-error');
			}
		});
	}
})();