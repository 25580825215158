(function () {
	const searchButton = document.querySelector('.search-button');
	const searchBox = document.querySelector('.search-input');

	if (searchButton && searchBox) {
		window.addEventListener('click', function (e) {
			if (searchButton.contains(e.target) || searchBox.contains(e.target)) {
				e.preventDefault();
				document.querySelector('.header-search-wrapper').classList.add('open');
			} else {
				document.querySelector('.header-search-wrapper').classList.remove('open');
			}
		});
	}
})();