(function () {
    const anchor = document.querySelector('#makeAPaymnet');
    if (!anchor) {
        return;
    }
    const $accountTypeRadio = $('input.make-a-payment-acc-type');
    const $paymentMethodRadio = $('input.make-a-payment-payment-method');
    const $amountOptionRadio = $('input.make-a-payment-payment-source');
    const $paymentPlanRadio = $('input.pay-plan-option');
    const $amountHiddenField = $('.jsMakePaymentAmount');
    const $customAmountField = $('.jsCustomPaymentAmount');
    const $step2Link = $('.jsStep2Link');
    const $takeSurveyLink = $('.jsTakeSurveyLink');
    const $displaySurvey = $('.jsDisplaySurvey')[0]?.value?.toLowerCase() === 'true';

    $accountTypeRadio.on('change', toggleAccountTypeFields);
    $paymentMethodRadio.on('change', togglePaymentMethodFields);
    $amountOptionRadio.on('change', handleAmountOptionChange);
    $paymentPlanRadio.on('change', togglePaymentPlanFields);
    $step2Link.on('click', submitStep2Form);
    $takeSurveyLink.on('click', hideSurveyModal);

    $(window).on('load', function () {
        toggleAccountTypeFields();
        togglePaymentMethodFields();
        handleAmountOptionChange();
        togglePaymentPlanFields();
        showSurveyModal();
    });

    function showSurveyModal() {
        if ($displaySurvey) {
            const $surveyModal = new Modal(document.getElementById('surveyModal'), { keyboard: false, backdrop: 'static' });
            $surveyModal.show();
        }
    }

    function hideSurveyModal() {
        const $surveyModal = Modal.getInstance(document.getElementById('surveyModal'))
        $surveyModal.hide();
    }

    function toggleAccountTypeFields() {
        const accountType = $accountTypeRadio.filter(':checked').val();
        const isPersonal = accountType === 'Personal';
        $('.accTypeComm').toggle(!isPersonal);
        $('.accTypePers').toggle(isPersonal);
    }

    function togglePaymentMethodFields() {
        const isCreditCardPayment = $paymentMethodRadio.filter(':checked').val() === 'CreditOrDebit';
        const isAchPayment = $paymentMethodRadio.filter(':checked').val() === 'ACH';
        $('.payment-ach').toggle(isAchPayment);
        $('.payment-cc').toggle(isCreditCardPayment);
    }

    function handleAmountOptionChange() {
        const $selectedOption = $amountOptionRadio.filter(':checked');
        $amountHiddenField.val($selectedOption.val());

        const isCustomAmountOption = !$selectedOption.val();
        if (isCustomAmountOption) {
            $customAmountField.removeAttr('disabled');
        } else {
            $customAmountField.attr('disabled', 'disabled');
            /* Remove validation messages - those are ignored for disabled fields anyways */
            $customAmountField.closest('.jsCustomPaymentAmountContainer').find('.error-msg,.input-error').hide();
        }
    }

    function togglePaymentPlanFields() {
        if ($('.pay-plan-option:checked').is('.jsDueDate')) {
            $('.make-payment-due-date').show();
        } else {
            $('.make-payment-due-date').hide();
        }
    }

    function submitStep2Form(e) {
        e.preventDefault();
        $('.jsStep2Form').submit();
    }

    function submitCreateOicAccountForm(form) {
        const $form = $(form);
        $.post($form.attr('action'), $form.serialize(), response => {
            const $resultPlaceholder = $form.find('.signup-oic-form__result');
            if (!response.success) {
                const errorMessage = response.errors.join('<br />');
                $resultPlaceholder.html(errorMessage).addClass('error-msg').removeClass('success-msg');
            } else {
                $resultPlaceholder.html(response.message).removeClass('error-msg').addClass('success-msg');
            }
        });
    }

    $('.make-a-payment-form').validate({
        onfocusout: function (element) {
            if ($(element).is('#Step1ViewModel_PolicyNumber')) { $('#Step1ViewModel_AccountNumber').valid() }
            if ($(element).is('#Step1ViewModel_AccountNumber')) { $('#Step1ViewModel_PolicyNumber').valid() }
            this.element(element);
        },
        rules: {
            'Step1ViewModel.PolicyNumber': {
                required: '#Step1ViewModel_AccountNumber:blank',
                exactlength: 10,
                noSpecialCharacters: true,
            },
            'Step1ViewModel.AccountNumber': {
                required: '#Step1ViewModel_PolicyNumber:blank',
                exactlength: 10,
                noSpecialCharacters: true,
            },
            'Step1ViewModel.HolderLastName': 'required',
            'Step1ViewModel.CompanyName': 'required',
            'Step1ViewModel.HolderZipCode': {
                required: true,
                exactlength: 5,
                digits: true,
            },
            'Step2ViewModel.PaymentAmount': {
                required: true,
                min: 1
            },
            'Step2ViewModel.PayorFirstName': 'required',
            'Step2ViewModel.PayorLastName': 'required',
            'Step2ViewModel.HolderEmail': {
                'email': true,
                'required': true,
            },
            'Step2ViewModel.PaymentMethod': 'required',
            'Step2ViewModel.BankRoutingNumber': {
                required: true,
                bankRoutingNumber: true
            },
            'Step2ViewModel.BankAccountNumber': {
                required: true,
                bankAccountNumber: true
            },
        },
        messages: {
            'Step1ViewModel.PolicyNumber': {
                required: 'Policy or Account Number is required.',
                exactlength: 'Policy Number should be exactly 10 characters long.',
            },
            'Step1ViewModel.AccountNumber': {
                required: 'Policy or Account Number is required.',
                exactlength: 'Account Number should be exactly 10 characters long.',
            },
            'Step1ViewModel.HolderLastName': {
                required: 'Last Name is required.',
            },
            'Step1ViewModel.CompanyName': {
                required: 'Company Name is required.',
            },
            'Step1ViewModel.HolderZipCode': {
                required: 'Holder Zip Code is required.',
                exactlength: 'Zip Code should be exactly 5 digits long.',
                digits: 'Only digits allowed for Zip Code.',
            },
            'Step2ViewModel.AccountOtherPaymentAmount': {
                required: 'Payment Amount is required.',
                min: 'You must pay an amount greater than or equal to $1.00.',
            },
            'Step2ViewModel.PayorFirstName': {
                required: `Payor's First Name is required`,
            },
            'Step2ViewModel.PayorLastName': {
                required: `Payor's Last Name is required.`,
            },
            'Step2ViewModel.HolderEmail': {
                required: 'Email is required.',
                email: `This email is invalid. Make sure it's written example@email.com.`,
            },
            'Step2ViewModel.PaymentMethod': {
                required: 'Payment Method is required.'
            },
            'Step2ViewModel.BankRoutingNumber': {
                required: 'Bank Routing Number is required.',
            },
            'Step2ViewModel.BankAccountNumber': {
                required: 'Bank Account Number is required.',
            },
        },
        errorClass: 'error-msg',
        errorElemet: 'span',
        errorPlacement: function (error, element) {
            if (element.is(':radio')) {
                error.insertBefore(element.closest('div'));
            } else if (!$(element).is('input#Step2ViewModel_AccountOtherPaymentAmount')) {
                error.insertAfter(element);
            }
        },
        highlight: function (element, errorClass, validClass) {
            if ($(element).is('input#Step2ViewModel_AccountOtherPaymentAmount')) {
                $(element).closest('.make-a-payment-other').find('.error-msg').show();
                $(element).parent().addClass('input-error');
            } else {
                $(element).parents('p').addClass('input-error');
            }
        },
        unhighlight: function (element, errorClass, validClass) {
            if ($(element).is('input#Step2ViewModel_AccountOtherPaymentAmount')) {
                $(element).closest('.make-a-payment-other').find('.error-msg').hide();
                $(element).parent().removeClass('input-error');
            } else {
                $(element).parents('p').removeClass('input-error');
            }
        }
    });

    $('.recurring-payment-form').validate({
        onfocusout: function (element) {
            this.element(element);
        },
        rules: {
            'FirstName': 'required',
            'LastName': 'required',
            'PhoneNumber': {
                required: true,
                phoneNumberFormat: true,
            },
            'PolicyholderEmail': {
                required: true,
                email: true
            },
            'BankName': 'required',
            'BankRoutingNumber': {
                required: true,
                bankRoutingNumber: true
            },
            'BankAccountNumber': {
                required: true,
                bankAccountNumber: true
            },
            'BankAccountNumber2': {
                required: true,
                equalTo: '#BankAccountNumber',
            },
            'IsBusinessAccountType': 'required',
            'RecurringPayPlanOption': 'required',
            'DueDay': 'required',
            'AcceptTerms': 'required',
        },
        messages: {
            'FirstName': {
                required: 'First Name is required.',
            },
            'LastName': {
                required: 'Last Name is required.',
            },
            'PhoneNumber': {
                required: 'Phone Number is required.',
            },
            'PolicyholderEmail': {
                required: 'Insured Email Address is required.'
            },
            'BankName': {
                required: 'Bank Name is required.',
            },
            'BankRoutingNumber': {
                required: 'Routing Number is required.',
            },
            'BankAccountNumber': {
                required: 'Account Number is required.',
            },
            'BankAccountNumber2': {
                required: 'Account Number is required.',
                equalTo: 'Numbers do not match.',
            },
            'IsBusinessAccountType': {
                required: 'Account Type is required.',
            },
            'RecurringPayPlanOption': {
                required: 'Pay Plan is required.',
            },
            'DueDay': {
                required: 'Due Date is required.',
            }
        },
        errorClass: 'error-msg',
        errorElement: 'span',
        errorPlacement: function (error, element) {
            if (element.is('input#AcceptTerms')) {
                error.insertAfter(element.siblings('label'));
            } else if (element.is('.pay-plan-option')) {
                error.insertAfter(element.closest('.pay-plan-selection').find('h4'));
            } else {
                error.insertAfter(element);
            }
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents('p').addClass('input-error');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents('p').removeClass('input-error');
        }
    });

    $('.signup-oic-form').validate({
        onfocusout: function (element) {
            this.element(element);
        },
        submitHandler: submitCreateOicAccountForm,
        rules: {
            'Email': {
                required: true,
                email: true
            },
            'ConfirmationEmail': {
                required: true,
                email: true,
                equalTo: "input[name=Email]"
            },
            'AcceptTerms': 'required',
        },
        messages: {
            'Email': {
                required: 'Email is required.'
            },
            'ConfirmationEmail': {
                required: 'Please confirm Email.',
                equalTo: 'The emails must match.'
            }
        },
        errorClass: 'error-msg',
        errorElement: 'span',
        errorPlacement: function (error, element) {
            if (element.is('input#AcceptTerms')) {
                error.insertAfter(element.siblings('label'));
            } else {
                error.insertAfter(element);
            }
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents('p').addClass('input-error');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents('p').removeClass('input-error');
        }
    });
})();
