(function() {
    if (!document.getElementById('business-risk-protection')) { return; }

    const $form = $('.business-risk-protection-form');
    $form.validate({
        onfocusout: function(element) {
            this.element(element);
        },
        rules: {
            PolicyNumber: 'required',
            CompanyName: 'required',
            EmailAddress: {
                required: true,
                email: true
            },
            ZipCode: {
                required: true,
                exactlength: 5,
                digits: true
            },
            AcceptTerms: 'required'
        },
        messages: {
            PolicyNumber: {
                required: 'Policy Number is required.'
            },
            CompanyName: {
                required: 'Company Name is required.'
            },
            EmailAddress: {
                required: 'Email Address is required.'
            },
            ZipCode: {
                required: 'Zip Code is required.',
                exactlength: 'Zip Code should be exactly 5 digits long.',
                digits: 'Only digits allowed for Zip Code.',
            },
            AcceptTerms: {
                required: 'You must accept Terms & Conditions.'
            }
        },
        errorClass: 'error-msg',
        errorElement: 'span',
        errorPlacement: function(error, element) {
            if (element.is('input#AcceptTerms')) {
                error.insertAfter(element.closest('div'));
            } else {
                error.insertAfter(element);
            }
        },
        highlight: function(element, errorClass, validClass) {
            $(element).parents('p').addClass('input-error');
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).parents('p').removeClass('input-error');
        }
    });

    const $form2 = $('.business-risk-protection-form-2');
    const $acceptTermsCheckbox = $form2.find('.jsAcceptTerms');
    const $step2NextButton = $form2.find('button[type=submit]');

    $form2.validate({
        onfocusout: function(element) {
            this.element(element);
        },
        rules: {
            AcceptTerms: 'required'
        },
        messages: {
            AcceptTerms: {
                required: 'You must accept Terms & Conditions.'
            }
        },
        errorClass: 'error-msg',
        errorElement: 'span',
        errorPlacement: function(error, element) {
            error.insertAfter(element.closest('div'));
        }
    });

    if (!!$acceptTermsCheckbox.length) {
        $acceptTermsCheckbox.on('change', disableNextButtonIfTermsNotAccepted);
        disableNextButtonIfTermsNotAccepted();
    }

    function disableNextButtonIfTermsNotAccepted() {
        const isChecked = $acceptTermsCheckbox.is(':checked');
        if (isChecked) {
            $step2NextButton.removeAttr('disabled');
        } else {
            $step2NextButton.attr('disabled', 'disabled');
        }
    }
})();