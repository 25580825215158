import { Accordion } from 'bootstrap'

(function() {
    const links = document.querySelectorAll('.js-accordion-link');
    links.forEach((link) => {
        link.addEventListener('click', (e) => {
            window.location.href = link.href;
        });
    });
})();
